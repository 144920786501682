import Axios from '@/http'
export default {
  saveImgTextMsgTem(params) {
    return new Promise((resolve, reject) => {
      Axios.post('imgTextMsgTem/saveImgTextMsgTem', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  }
}
