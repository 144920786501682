import Axios from '@/http'

export default {
  orgNearBy(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/org/nearBy', params).then((res) => {
        resolve(res)
      })
    })
  },
  dispatch(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/dispatch', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 警情状态编辑 */
  updateCaseStatus(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/updateCaseStatus', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 下发短信 */
  sendMsg(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/sendMsg', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 调派部门的固定群组
  nature(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/orgGroup/nature', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 警情位置推送
  caseLocationPush(caseId) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/caseLocation/push', { caseId }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 警情临时群组创建 */
  insertPoliceCaseTemp(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/orgGroup/insertPoliceCaseTemp', params).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  /* 火场文书编辑、提交 */
  pcFireDocUpdate(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/pcFireDoc/update', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 客户端信息 */
  clientInfoByCaseId(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/clientInfoByCaseId', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 火场文书 详情 */
  policeCasePcFireDoc(caseId) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/pcFireDoc', { caseId }).then((res) => {
        resolve(res)
      })
    })
  },
  pcDispatchDetail(caseId) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/pcDispatch/detail', { caseId }).then((res) => {
        resolve(res)
      })
    })
  },
  /* 出动填报-查找 */
  pcDispatchQuery(caseId) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/pcDispatch/query', { caseId }).then((res) => {
        resolve(res)
      })
    })
  },
  // 邀请上级部门调度台、作战设备加入视频会议
  videoMeetingInviteDevice(params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/videoMeetingInvite/device', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 警情操作 （下发，调派数据差不多一样，所以合并成了一个）
  alarmOperation(controller, params) {
    return new Promise((resolve, reject) => {
      Axios.post(`policeCase/${controller}`, params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
