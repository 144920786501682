/* 路由 */
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dailyCheck'
  },
  {
    path: '/dailyCheck',
    name: 'dailyCheck',
    meta: {
      label: '考勤'
    },
    component: () => import('@/viewsv2/dailyCheck/dailyCheck')
  },
  {
    path: '/workTime',
    name: 'workTime',
    meta: {
      label: '班次'
    },
    component: () => import('@/viewsv2/workTime/workTime')
  },
  {
    path: '/workTime',
    name: 'workTime',
    meta: {
      label: '班次'
    },
    component: () => import('@/viewsv2/workTime/workTime')
  },
  {
    path: '/checkReport/dailyCheckUser',
    name: 'dailyCheckUser',
    meta: {
      label: '用户考勤统计汇总'
    },
    component: () => import('@/viewsv2/checkReport/dailyCheckUser.vue')
  },
  {
    path: '/checkReport/dailyCheckDay',
    name: 'dailyCheckDay',
    meta: {
      label: '每日考勤统计'
    },
    component: () => import('@/viewsv2/checkReport/dailyCheckDay.vue')
  },
  {
    path: '/checkReport/byDeviceUser',
    name: 'byDeviceUser',
    meta: {
      label: '抽查记录'
    },
    component: () => import('@/viewsv2/checkReport/byDeviceUser.vue')
  },
  {
    path: '/checkReport/byDailyCheck',
    name: 'byDailyCheck',
    meta: {
      label: '查岗记录'
    },
    component: () => import('@/viewsv2/checkReport/byDailyCheck.vue')
  }
]
/* 侧边导航 */
const menu = [
  {
    label: '班次',
    permission: 'dailyCheck_workTime',
    name: 'workTime',
    path: '/workTime'
  },
  {
    label: '考勤',
    permission: 'dailyCheck_dailyCheck',
    name: 'dailyCheck',
    path: '/dailyCheck'
  },
  {
    label: '报表',
    permission: 'dailyCheck_checkReport',
    name: 'checkReport/dailyCheckUser',
    path: 'checkReport/dailyCheckUser',
    childNavigation: [
      {
        permission: 'dailyCheck_checkReport_dailyCheckUser',
        type: 'checkReport/dailyCheckUser',
        name: '用户考勤统计汇总'
      },
      {
        permission: 'dailyCheck_checkReport_dailyCheckDay',
        type: 'checkReport/dailyCheckDay',
        name: '每日考勤统计'
      },
      {
        permission: 'dailyCheck_checkReport_byDeviceUser',
        type: 'checkReport/byDeviceUser',
        name: '抽查记录'
      },
      {
        permission: 'dailyCheck_checkReport_byDailyCheck',
        type: 'checkReport/byDailyCheck',
        name: '查岗记录'
      }
    ]
  }
]
// 用户引导数据列表
const userGuidanceList = [
  // left, left-center, left-bottom, top, top-center, top-right, right, right-center,right-bottom或 bottom, bottom-center, bottom-right, mid-center。
  {
    element: '#dailyCheck',
    popover: {
      title: '考勤',
      description: '设置班次与考勤组并可查询考勤人员的考勤记录。',
      position: 'bottom'
    }
  },
  {
    element: '#dailyCheck_workTime',
    permission: 'dailyCheck_workTime',
    parameter: 'workTime',
    path: '/workTime',
    popover: {
      title: '班次',
      description: '添加、编辑、删除班次。',
      position: 'bottom'
    }
  },
  {
    element: '#dailyCheck_dailyCheck',
    permission: 'dailyCheck_dailyCheck',
    parameter: 'dailyCheck',
    path: '/dailyCheck',
    popover: {
      title: '考勤',
      description: '添加、编辑、删除考勤组。',
      position: 'bottom'
    }
  },
  {
    element: '#dailyCheck_checkReport',
    permission: 'dailyCheck_checkReport',
    parameter: 'checkReport',
    path: '/checkReport',
    popover: {
      title: '报表',
      description: '查看个考勤用户的考勤记录。',
      position: 'bottom'
    }
  }
]

/* 组件内容 */
const componentsContent = {
  /* 页面 */
  dailyCheck: 'dailyCheck_shangrao', // 考勤列表
  dailyCheckUser: 'dailyCheckUser_shangrao', // 用户考勤统计汇总
  dailyCheckDay: 'dailyCheckDay_shangrao', // 每日考勤统计

  /* 组件 */
  addWorkTime: 'addWorkTime_shangrao' /* 新增班次 */,
  addDailyChecke: 'addDailyChecke_dispatch' /* 新增考勤组 */,
  attendanceDetails: 'attendanceDetails_dispatch' /* 考勤明细 */,
  dailyCheckDayDetails:
    'dailyCheckDayDetails_shangrao' /* 每日统计的考勤详情 */,
  singleDayDetails: 'singleDayDetails_shangrao' /* 考勤明细中的考勤详情 */
}

module.exports = {
  routes,
  menu,
  userGuidanceList,
  componentsContent
}
