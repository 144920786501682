// 数据管理
import Axios from '@/http'
export default {
  // POST /admin/orgOosKey/preview
  //  数据删除
  previewFn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('orgOosKey/preview', params).then((res) => {
        resolve(res)
      })
    })
  },
  //  数据删除
  delObject(params) {
    return new Promise((resolve, reject) => {
      Axios.post('orgOosKey/delObject', params).then((res) => {
        resolve(res)
      })
    })
  },
  //  数据天数更改
  updateOosKeyFn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('orgOosKey/updateOosKey', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  // 数据天数详情
  orgOosKeyDetailFn() {
    return new Promise((resolve, reject) => {
      Axios.post('orgOosKey/orgOosKeyDetail').then((res) => {
        resolve(res)
      })
    })
  },
  // 获取oos文件列表
  listObjectFoldFn(params) {
    return new Promise((resolve, reject) => {
      Axios.post('orgOosKey/listObjectFold', params).then((res) => {
        resolve(res)
      })
    })
  }
}
