import http from '@/http'

export default {
  /* 上传文件 */
  upload(params) {
    return new Promise((resolve, reject) => {
      http
        .post('/file/upload', params, { otherUrl: true, xAmzAcl: true })
        .then((res) => {
          resolve(res)
        })
    })
  }
}
