import Axios from '@/http'
export default {
  // 设备的当前时间的检查情况
  deviceCountCurrentCount() {
    return new Promise((resolve, reject) => {
      Axios.post('paiban/report/deviceCount/currentCount').then((res) => {
        resolve(res)
      })
    })
  },
  // 每日考勤统计（xxx日xxx部门的）
  dayCountOneDay(params) {
    return new Promise((resolve, reject) => {
      Axios.post('paiban/report/dayCount/oneDay', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 用户考勤统计（xxx天的）
  userCountOneDay(params) {
    return new Promise((resolve, reject) => {
      Axios.post('paiban/report/userCount/oneDay', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 用户考勤统计（日期情况） */
  userCountDayList(params) {
    return new Promise((resolve, reject) => {
      Axios.post('paiban/report/userCount/dayList', params, {
        isJSON: true
      }).then((res) => {
        resolve(res)
      })
    })
  }
}
