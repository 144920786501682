import Axios from '@/http'
export default {
  waterPointTypeSaveRankSort(params) {
    return new Promise((resolve, reject) => {
      Axios.post('waterPoint/waterPointType/saveRankSort', params, {
        isJSON: true
      }).then((res) => {
        return resolve(res.data)
      })
    })
  },
  officeTypeSaveRankSort(params) {
    return new Promise((resolve, reject) => {
      Axios.post('fireControlOffice/officeType/saveRankSort', params, {
        isJSON: true
      }).then((res) => {
        return resolve(res.data)
      })
    })
  },
  buildFormSaveRankSort(params) {
    return new Promise((resolve, reject) => {
      Axios.post('fireControlOffice/buildForm/saveRankSort', params, {
        isJSON: true
      }).then((res) => {
        return resolve(res.data)
      })
    })
  }
}
