import Axios from '@/http'
export default {
  deviceUserFindAll() {
    return new Promise((resolve, reject) => {
      Axios.post('deviceUserFile/deviceUserFindAll').then((res) => {
        resolve(res)
      })
    })
  },
  devUserFileCreateDate(devUserId) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceUserFile/devUserFileCreateDate', { devUserId }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  mediaFileFindAll(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceUserFile/mediaFileFindAll', params).then((res) => {
        resolve(res)
      })
    })
  }
}
