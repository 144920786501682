import Axios from '@/http'
export default {
  assigned(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceRule/assigned', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  cancelAssigned(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceRule/cancelAssigned', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  },
  deviceRule(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceRule/devRule', params).then((res) => {
        resolve(res)
      })
    })
  },
  saveDevRule(params) {
    return new Promise((resolve, reject) => {
      Axios.post('deviceRule/saveDevRule', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
