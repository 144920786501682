<template>
  <div id="app">
    <div class="p_20 h_100vh" style="box-sizing: border-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :key="index"
          v-for="(item, index) in menuList"
          :label="item.label"
          :name="item.name"
        >
          <template slot="label">
            <span :id="item.permission">
              {{ item.label }}
            </span>
          </template>
          <div
            class="childrenTabs"
            v-if="item.childNavigation && item.childNavigation.length"
          >
            <el-tabs v-model="childType" @tab-click="childTypeFn">
              <el-tab-pane
                v-for="(item, index) in item.childNavigation"
                :key="index"
                :label="item.name"
                :name="item.type"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="height: calc(100% - 80px)">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="less">
/*@import "styles/main.css";*/
/*@import 'styles/basics.less';*/
.operator-wrapper {
  justify-content: space-between;
  /*padding: 12px 20px;*/
  height: 100%;
  .operator-menu-box {
  }
  .operator-content {
    width: 100%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
<script type="text/ecmascript-6">
  const { menu, userGuidanceList } = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
  export default {
  data () {
    return {
      defineStepsList: null,
      childType: null,
      childNavigation: [],
      activeName: null,
      userGuidanceIndex: null,
      userGuidanceList: userGuidanceList,
      menuList: menu
    }
  },
  components: {
  },
  watch: {
      'userGuidance.header': function (v) {
        if (v) {
          this.userGuidanceFn()
        }
      }
  },
  computed: {
    userGuidance () {
      return this.mainStore.state.user.userGuidance
    }
  },
  methods: {
    /* 考勤方式 */
    checkTypeFindAllFn () {
      this.$api.findAll('attendance/dailyCheck/checkType').then(res => {
        if (res.code === 200) {
          this.$store.commit('dailyCheckCheckTypeList', res.data || [])
        }
      })
    },
    /* 用户引导 */
    userGuidanceFn () {
      if (this.userGuidanceList && this.userGuidance) {
        if (this.userGuidanceList.length && !this.userGuidance.dailyCheck && this.userGuidance.header) {
          this.$deleteConfirmationBox(
            {
              deleteTitle: '功能介绍',
              submitClass: 'button_function',
              deleteContent: '<div class="c_9DA7B2" style="letter-spacing: 2px">是否需要新手指引<span class="c_fff">考勤说明</span>？</div>',
              closeText: '跳 过',
              submitText: '确 定'
            },
            () => {
              const userGuidance = this.mainStore.getters.windowFn.userGuidance
              const defineStepsList = []
              const permissionIdList = JSON.parse(window.sessionStorage.getItem('permissionIdList')) || []
              /* 判断是否有权限 */
              const userGuidanceList = this.userGuidanceList.filter((v) => {
                if (v.permission) {
                  return permissionIdList.findIndex(permission => permission === v.permission) !== -1
                } else {
                  return true
                }
              })
              const num = userGuidanceList.length
              /* 整理引导数据 */
              userGuidanceList.forEach((row, index) => {
                const item = {
                  element: row.element,
                  popover: { ...row.popover }
                }
                let li = ''
                for (let i = 0; i < num; i++) {
                  li += i === index ? '<li class="select"></li>' : '<li></li>'
                }
                if (index === 0) {
                  item.popover.className = 'first-step-class'
                  item.onPrevious = () => {
                    userGuidance.preventMove()
                  }
                }
                item.popover.description = `<div class="content">${item.popover.description}</div><ul class="centerBox">${li}</ul>`
                item.onHighlightStarted = () => {
                  if (row.query && row.path) {
                    this.$router.push({ path: row.path, query: row.query })
                  } else {
                    if (row.path) {
                      this.$router.push({ path: row.path })
                    }
                  }
                  if (row.parameter) {
                    this.activeName = row.parameter
                  }
                }
                item.onNext = () => {
                }
                defineStepsList.push(item)
              })
              /* 标记当前引导的板块 */
              this.mainStore.commit('guidanceSectionName', 'dailyCheck')
              this.$nextTick(() => {
                this.defineStepsList = defineStepsList
                userGuidance.defineSteps(defineStepsList)
                userGuidance.start()
              })
            },
            () => {
              this.mainStore.dispatch('userGuidanceFn', {
                name: 'dailyCheck',
                content: 1
              })
            }
          )
        }
      }
    },
    childTypeFn () {
      this.$router.push({ path: `/${this.childType}` })
    },
    handleClick () {
      this.childType = this.activeName
      this.$router.push({ path: `/${this.activeName}` })
    }
  },
  mounted () {
    // dailyCheckTypeList
    let userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.$store.commit('setUserInfo', userInfo)
      this.checkTypeFindAllFn()
    }
    let permissionIdList = window.sessionStorage.getItem('permissionIdList')
    if (permissionIdList.length !== 0) {
      permissionIdList = JSON.parse(permissionIdList)
      this.$nextTick(() => {
        const dataList = []
        for (let i = 0; i < this.menuList.length; i++) {
          const item = this.menuList[i]
          if (permissionIdList.find(v => v === item.permission)) {
            dataList.push({ ...item })
          }
        }
        this.$nextTick(() => {
          this.menuList = dataList
          if (this.menuList.length) {
            this.activeName = this.menuList[0].name
            this.handleClick()
          }
          this.$nextTick(() => {
            this.userGuidanceFn()
          })
        })
      })
    }
  }
}
</script>
