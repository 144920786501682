import Axios from '@/http'
export default {
  saveImgTextMsg(params) {
    return new Promise((resolve, reject) => {
      Axios.post('imgTextMsg/saveImgTextMsg', params, { isJSON: true }).then(
        (res) => {
          resolve(res)
        }
      )
    })
  }
}
