class HttpConfig {
  constructor() {
    this.baseURLObj = {}
  }

  initHttpConfig(config) {
    const { baseURLObj } = config

    this.baseURLObj = baseURLObj
  }
}

export default new HttpConfig()
